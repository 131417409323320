"use strict";
exports.__esModule = true;
exports.Easing = void 0;
var Easing = /** @class */ (function () {
    function Easing() {
    }
    Easing.linear = function (pos) { return pos; };
    Easing.easeInQuad = function (pos) { return Math.pow(pos, 2); };
    Easing.easeOutQuad = function (pos) { return -(Math.pow(pos - 1, 2) - 1); };
    Easing.easeInOutQuad = function (pos) {
        return (pos /= 0.5) < 1 ? 0.5 * Math.pow(pos, 2) : -0.5 * ((pos -= 2) * pos - 2);
    };
    Easing.easeInCubic = function (pos) { return Math.pow(pos, 3); };
    Easing.easeOutCubic = function (pos) { return Math.pow(pos - 1, 3) + 1; };
    Easing.easeInOutCubic = function (pos) {
        return (pos /= 0.5) < 1 ? 0.5 * Math.pow(pos, 3) : 0.5 * (Math.pow(pos - 2, 3) + 2);
    };
    Easing.easeInQuart = function (pos) { return Math.pow(pos, 4); };
    Easing.easeOutQuart = function (pos) { return -(Math.pow(pos - 1, 4) - 1); };
    Easing.easeInOutQuart = function (pos) {
        return (pos /= 0.5) < 1
            ? 0.5 * Math.pow(pos, 4)
            : -0.5 * ((pos -= 2) * Math.pow(pos, 3) - 2);
    };
    Easing.easeInQuint = function (pos) { return Math.pow(pos, 5); };
    Easing.easeOutQuint = function (pos) { return Math.pow(pos - 1, 5) + 1; };
    Easing.easeInOutQuint = function (pos) {
        return (pos /= 0.5) < 1 ? 0.5 * Math.pow(pos, 5) : 0.5 * (Math.pow(pos - 2, 5) + 2);
    };
    Easing.easeInSine = function (pos) { return -Math.cos(pos * (Math.PI / 2)) + 1; };
    Easing.easeOutSine = function (pos) { return Math.sin(pos * (Math.PI / 2)); };
    Easing.easeInOutSine = function (pos) { return -0.5 * (Math.cos(Math.PI * pos) - 1); };
    Easing.easeInExpo = function (pos) { return (pos === 0 ? 0 : Math.pow(2, 10 * (pos - 1))); };
    Easing.easeOutExpo = function (pos) { return (pos === 1 ? 1 : -Math.pow(2, -10 * pos) + 1); };
    Easing.easeInOutExpo = function (pos) {
        if (pos === 0) {
            return 0;
        }
        if (pos === 1) {
            return 1;
        }
        if ((pos /= 0.5) < 1) {
            return 0.5 * Math.pow(2, 10 * (pos - 1));
        }
        return 0.5 * (-Math.pow(2, -10 * --pos) + 2);
    };
    Easing.easeInCirc = function (pos) { return -(Math.sqrt(1 - pos * pos) - 1); };
    Easing.easeOutCirc = function (pos) { return Math.sqrt(1 - Math.pow(pos - 1, 2)); };
    Easing.easeInOutCirc = function (pos) {
        return (pos /= 0.5) < 1
            ? -0.5 * (Math.sqrt(1 - pos * pos) - 1)
            : 0.5 * (Math.sqrt(1 - (pos -= 2) * pos) + 1);
    };
    Easing.easeOutBounce = function (pos) {
        if (pos < 1 / 2.75) {
            return 7.5625 * pos * pos;
        }
        else if (pos < 2 / 2.75) {
            return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
        }
        else if (pos < 2.5 / 2.75) {
            return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
        }
        else {
            return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
        }
    };
    Easing.easeInBack = function (pos) {
        var s = 1.70158;
        return pos * pos * ((s + 1) * pos - s);
    };
    Easing.easeOutBack = function (pos) {
        var s = 1.70158;
        return (pos = pos - 1) * pos * ((s + 1) * pos + s) + 1;
    };
    Easing.easeInOutBack = function (pos) {
        var s = 1.70158;
        if ((pos /= 0.5) < 1) {
            return 0.5 * (pos * pos * (((s *= 1.525) + 1) * pos - s));
        }
        return 0.5 * ((pos -= 2) * pos * (((s *= 1.525) + 1) * pos + s) + 2);
    };
    Easing.elastic = function (pos) {
        return -1 * Math.pow(4, -8 * pos) * Math.sin(((pos * 6 - 1) * (2 * Math.PI)) / 2) +
            1;
    };
    Easing.swingFromTo = function (pos) {
        var s = 1.70158;
        return (pos /= 0.5) < 1
            ? 0.5 * (pos * pos * (((s *= 1.525) + 1) * pos - s))
            : 0.5 * ((pos -= 2) * pos * (((s *= 1.525) + 1) * pos + s) + 2);
    };
    Easing.swingFrom = function (pos) {
        var s = 1.70158;
        return pos * pos * ((s + 1) * pos - s);
    };
    Easing.swingTo = function (pos) {
        var s = 1.70158;
        return (pos -= 1) * pos * ((s + 1) * pos + s) + 1;
    };
    Easing.bounce = function (pos) {
        if (pos < 1 / 2.75) {
            return 7.5625 * pos * pos;
        }
        else if (pos < 2 / 2.75) {
            return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
        }
        else if (pos < 2.5 / 2.75) {
            return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
        }
        else {
            return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
        }
    };
    Easing.bouncePast = function (pos) {
        if (pos < 1 / 2.75) {
            return 7.5625 * pos * pos;
        }
        else if (pos < 2 / 2.75) {
            return 2 - (7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75);
        }
        else if (pos < 2.5 / 2.75) {
            return 2 - (7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375);
        }
        else {
            return 2 - (7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375);
        }
    };
    Easing.easeFromTo = function (pos) {
        return (pos /= 0.5) < 1
            ? 0.5 * Math.pow(pos, 4)
            : -0.5 * ((pos -= 2) * Math.pow(pos, 3) - 2);
    };
    Easing.easeFrom = function (pos) { return Math.pow(pos, 4); };
    Easing.easeTo = function (pos) { return Math.pow(pos, 0.25); };
    return Easing;
}());
exports.Easing = Easing;

"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
exports.__esModule = true;
exports.Tweener = void 0;
var easing_1 = require("./easing");
var Tweener = /** @class */ (function () {
    function Tweener() {
    }
    Tweener.init = function (ticker) {
        if (!Tweener.ticker) {
            Tweener.ticker = ticker;
            Tweener.ticker.add(Tweener.update);
        }
    };
    Tweener.tweening = function () {
        return Tweener.tweens.length > 0;
    };
    Tweener.dispose = function () {
        if (Tweener.ticker) {
            Tweener.ticker.remove(Tweener.update);
            Tweener.ticker = undefined;
            Tweener.tweens = [];
        }
    };
    Tweener.add = function (tweenParams, props) {
        var target = tweenParams.target;
        var startingProps = Object.assign.apply(Object, __spreadArrays([{}], Object.keys(props).map(function (key) {
            var _a;
            return (_a = {}, _a[key] = target[key], _a);
        })));
        var propDeltas = Object.assign.apply(Object, __spreadArrays([{}], Object.keys(props).map(function (key) {
            var _a;
            return (_a = {}, _a[key] = props[key] - startingProps[key], _a);
        })));
        var duration = tweenParams.useTickForDuration ? tweenParams.duration : tweenParams.duration * 1000;
        return new Promise(function (resolve) {
            var tween = {
                duration: duration,
                useTickForDuration: tweenParams.useTickForDuration ? true : false,
                ease: tweenParams.ease || easing_1.Easing.linear,
                target: tweenParams.target,
                delay: tweenParams.delay ? tweenParams.delay * 1000 : 0,
                currentTime: 0,
                props: props,
                propDeltas: propDeltas,
                startingProps: startingProps,
                onComplete: resolve,
                onUpdate: tweenParams.onUpdate || undefined,
                context: tweenParams.context || target
            };
            Tweener.tweens.push(tween);
        });
    };
    Tweener.killTweensOf = function (context, skipComplete) {
        var filteredTweens = [];
        Tweener.tweens.forEach(function (tween) {
            if (tween.context === context || tween.target === context) {
                !skipComplete && tween.onComplete();
            }
            else {
                filteredTweens.push(tween);
            }
        });
        Tweener.tweens = filteredTweens;
    };
    Tweener.advance = function (elapsedMS) {
        var hasCompletedTween = false;
        for (var _i = 0, _a = Tweener.tweens; _i < _a.length; _i++) {
            var tween = _a[_i];
            // if tick count is used for tweening, each update call moved everything by 1 tick
            var elapsed = tween.useTickForDuration ? 1 : elapsedMS;
            if (tween.delay > 0) {
                tween.delay -= elapsed;
                if (tween.delay <= 0) {
                    elapsed = -tween.delay;
                }
                else {
                    continue;
                }
            }
            tween.currentTime += elapsed;
            var t = Math.min(1, tween.currentTime / tween.duration);
            var propDelta = tween.ease(t);
            for (var _b = 0, _c = Object.keys(tween.props); _b < _c.length; _b++) {
                var key = _c[_b];
                tween.target[key] = tween.startingProps[key] + propDelta * tween.propDeltas[key];
            }
            if (tween.onUpdate) {
                tween.onUpdate(t);
            }
            if (tween.currentTime >= tween.duration) {
                tween.onComplete();
                hasCompletedTween = true;
            }
        }
        if (hasCompletedTween) {
            Tweener.tweens = Tweener.tweens.filter(function (tween) { return tween.currentTime < tween.duration; });
        }
    };
    Tweener.tweens = [];
    Tweener.update = function () { return Tweener.advance(Tweener.ticker.elapsedMS); };
    return Tweener;
}());
exports.Tweener = Tweener;
